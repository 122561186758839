import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { Observable } from 'rxjs';
import { PaymentUrlResponse } from '../models/payment-url-response.model';
import { AppConfigService } from './app-config.service';

@Injectable({ providedIn: 'root' })

export class PaymentService {
    constructor(
        private http: HttpClient,
        public appConfigService: AppConfigService
      ) { }
      backendUrl = this.appConfigService.appConfig.MDELIVERY_ORDER_LINK;
      serverUrl = this.appConfigService.appConfig.SERVER_URL

//   getPayData(orderId: string): Observable<PaymentUrlResponse> {
//     let returnUrl = this.accountLinkGeneratorService.getOrderConfirmationUrl(orderId);
//     return this.http.get<PaymentUrlResponse>(`${this.api}/pay?orderId=${orderId}&redirectUrl=${returnUrl}`)
//   }

    getPayData(id: string): any {
          const api = `${this.appConfigService.appConfig.APP_BASE_URL}`
      const headers = new HttpHeaders({
        'Access-Control-Allow-Headers': 'Content-Type',
        'Access-Control-Allow-Origin': '*'
      })
      return window.location.href = `${this.backendUrl}?&orderId=${id}&returUrl=${api}/&lang=en&mpass=true&serviceId=1`;
      // return this.http.get<PaymentUrlResponse>(`${this.backendUrl}?orderId=${id}&lang=en&mpass=true&serviceId=1`, {headers});
    }


    doingPayment(orderId: string, deliveryId: string, returUrl: string): Observable<object> {
        let params = new HttpParams();
        params = params.append('orderId', orderId);
        params = params.append('deliveryOrderId', deliveryId);
        // params = params.append('redirectUrl', returUrl);returUrl
        return this.http.get(`${this.serverUrl}/pay`, {params})
    }

}
