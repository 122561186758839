
import {Component} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Order} from '../../core/models/Order.model';
import {OrdersService} from '../../core/services/orders.service';

@Component({
  selector: 'sp-get-order',
  templateUrl: './get-order.component.html',
  styleUrls: ['./get-order.component.scss']
})
export class GetOrderComponent {
  searchForm: FormGroup = new FormGroup({
    searchIdValue: new FormControl('', [Validators.required])
  });
  order?: Order;
  searchError = false;
  errorJson?: any;
  httpRequestInProgress = false;

  // Mock data for table
  displayedColumns: string[] = ['keyName', 'type', 'meaning'];
  dataSource = [
    {
      keyName: 'orderId',
      type: 'string',
      meaning: 'This field is a numeric value that uniquely identifies an order, id is generated on backend side in create order process.'
    },
    {
      keyName: 'price',
      type: 'number',
      meaning: 'It is a numeric value that represent costs of "Service Provider" service (current price does not include delivery costs).'
    },
    {
      keyName: 'receiver',
      type: 'Object',
      meaning: 'This field represents information about receiver person. It contain 2 subfields: type and value, type represents type of licence number (ex. idnp) and value represents number of licence (ex. idnp number).'
    },
    {keyName: 'submissionDate', type: 'Date', meaning: 'The date when you requested for "Service provider" service.'},
    {
      keyName: 'estimatedDate',
      type: 'Date',
      meaning: 'The date when "Service provider" service will be done / ready for pickup at his address.'
    },
    {
      keyName: 'parcel',
      type: 'Object',
      meaning: 'This field represents the data about envelope / box in which will be delivered requested product: length(cm), width(cm), height(cm), weight(kg), quality, description.'
    },
    {
      keyName: 'trackingId',
      type: 'string',
      meaning: 'This field represents the string unique id with who we can track delivery stage of our requested service.'
    },
    {
      keyName: 'Product Code',
      type: 'string',
      meaning: 'Define code of product that is requested by user'
    },
    {
      keyName: 'Pick-up point  Code',
      type: 'string',
      meaning: 'Define code of pick-up pint where the user can receive his product'
    }
  ];

  constructor(private ordersService: OrdersService) {
  }

  search(): void {
    if (!this.searchForm.invalid) {
      this.httpRequestInProgress = true;

      this.ordersService.getOrderById(this.searchForm.get('searchIdValue')?.value).subscribe(res => {
        this.order = res;

        this.searchError = false;
        this.errorJson = undefined;
        this.httpRequestInProgress = false;
      }, (error) => {
        this.order = undefined;
        this.searchError = true;
        this.errorJson = error.error;
        this.httpRequestInProgress = false;
      });
    }
  }

  hasError(fieldControl: string, validatorType: string): boolean {
    return this.searchForm.controls[fieldControl].hasError(validatorType);
  }
}
