import { Icarrie } from "../interface/icarrie";
import { IdeliveryAddress } from "../interface/idelivery-address";
import { IdeliveryContactPerson } from "../interface/idelivery-contact-person";
import { IProductModel } from "../interface/iproduct-model";
import { Ireceiver } from "../interface/ireceiver";
import { Iservice } from "../interface/iservice";
import { IStatusData } from "../interface/istatus-data";


export class DeliveryDetailsModel {
  id?: string;
  carrierName?: string;
  trackingId?: string;
  carrierEstimatedDeliveryEnd?: string;
  carrierCost?: number;
  deliveryId?: string;
  providerServicesId?: string;
  status?: number;
  cost?: number;
  paymentDueDate?: string;
  orderDate?: string;
  receiver?: Ireceiver;
  service?: Iservice;
  carrier?: Icarrie;
  deliveryAddress?: IdeliveryAddress;
  deliveryContactPerson?: IdeliveryContactPerson;
  products?: Array<IProductModel>;
  historyStatuses?: IStatusData[];
}
