import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DeliveryDetailsModel } from '../models/delivery-details.model';
import { AppConfigService } from './app-config.service';

@Injectable({
  providedIn: 'root'
})
export class DeliveryService {

  constructor(
    private http: HttpClient,
    public appConfigService: AppConfigService
  ) { }
  backendUrl = this.appConfigService.appConfig.SERVER_URL + "/delivery";

  testUrl = "https://localhost:44322/api/v1/delivery";
  testId = "0211190133550829";
  getDeliveryDetailsByDeliveryId(serviceId: string, orderId: string): Observable<DeliveryDetailsModel> {
    console.log(serviceId), console.log(orderId)
    return this.http.get(`${this.backendUrl}/services/${serviceId}/orders/${orderId}/delivery/details`);
  }
}
