import {AfterViewInit, Component, OnInit} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Order } from '../../core/models/Order.model';
import { ProcessingTimeEnum } from '../../core/enums/ProcessingEnum';
import { OrdersService } from '../../core/services/orders.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component';
import { LanguagesEnum } from '../../core/enums/LanguagesEnum';
import { PaymentUrlResponse } from '../../core/models/payment-url-response.model';
import { PaymentService } from '../../core/services/payment.service';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { DeliveryDetailsModel } from '../../core/models/delivery-details.model';
import { ActivatedRoute, Params } from '@angular/router';
import { DeliveryService } from '../../core/services/delivery.service';
import { FeedbackService } from '../../core/services/feedback-service.service';
import { DatePipe } from '@angular/common';
import { AppConfigService } from '../../core/services/app-config.service';
import { finalize } from 'rxjs/operators';

@Component({
    selector: 'sp-create-order-form',
    templateUrl: './create-order-form.component.html',
    styleUrls: ['./create-order-form.component.scss']
})
export class CreateOrderFormComponent implements OnInit, AfterViewInit {

    constructor(
        private ordersService: OrdersService,
        private appConfigService: AppConfigService,
        private paymentService: PaymentService,
        public activatedRoute: ActivatedRoute,
        public http: HttpClient,
        private deliveryService: DeliveryService,
        private feedbackService: FeedbackService,
        private datePipe: DatePipe,
        public dialog: MatDialog) {
    }

    providerForm = new FormGroup({
        idnp: new FormControl('', [Validators.required]),
        processing: new FormControl('', [Validators.required]),
        productCode: new FormControl(''),
        pickUpPointCode: new FormControl(''),
        lang: new FormControl(LanguagesEnum.EN, [Validators.required]),
        mpass: new FormControl(true, [Validators.required]),
    });
    params: Params = new HttpParams();
    processingTimeEnum = ProcessingTimeEnum;
    orderData: any;
    languagesEnum = LanguagesEnum;

    savedOrder?: Order;
    deliveryDetails: DeliveryDetailsModel = new DeliveryDetailsModel()
    deliveryId: string = this.activatedRoute.snapshot.queryParams.deliveryId;
    orderId = this.activatedRoute.snapshot.queryParams.orderId;
    serviceId = this.activatedRoute.snapshot.queryParams.serviceId;
    loading?: boolean;
    totalProductsPrice: number = 0;

    httpRequestInProgress = false;

    displayedColumns: string[] = ['keyName', 'type', 'meaning'];
    dataSource = [
        { keyName: 'IDNP', type: 'string', meaning: 'Define user identity.' },
        {
            keyName: 'Processing time',
            type: 'string',
            meaning: 'Define number of days and price of requested service to be finished for pickup. Value of selected option is Enum "Ordinary" for 10 days and "Urgent" for 1 day'
        },
        {
            keyName: 'Product Code',
            type: 'string',
            meaning: 'Define code of product that is requested by user'
        },
        {
            keyName: 'Pick-up point  Code',
            type: 'string',
            meaning: 'Define code of pick-up pint where the user can receive his product'
        }
    ];
    returning: boolean = false;

    

    ngOnInit() {
        this.params = this.activatedRoute.snapshot.queryParamMap
        console.log(this.params.get('orderId'))
        this.getDataOrder()
        this.getDeliveryDetails();


        setTimeout(() => {

        }, 500)
    }


    submitForm(): void {
        if (!this.providerForm.invalid) {
            this.httpRequestInProgress = true;

            this.ordersService.createOrder(this.providerForm.value).subscribe(val => {
                console.log(val)
                this.savedOrder = val;
                this.httpRequestInProgress = false;
            });
        }
    }


    openDialog(): void {
        const dialogRef = this.dialog.open(ConfirmModalComponent, {
            width: '250px'
        });

        dialogRef.afterClosed().subscribe(result => {
            this.savedOrder = undefined;
            this.providerForm.reset();
        });
    }

    hasError(fieldControl: string, validatorType: string): boolean {
        return this.providerForm.controls[fieldControl].hasError(validatorType);
    }

    redirect(): void {
        if (this.savedOrder && this.savedOrder.orderId) {

            const langCode = this.providerForm.get('lang')?.value;
            const mpassVal = this.providerForm.get('mpass')?.value;
            //window.location.href = this.appConfigService.appConfig.MDELIVERY_ORDER_LINK + `?orderId=${this.savedOrder.orderId}&lang=${langCode}&mpass=${mpassVal}&serviceId=1`;
            this.paymentService.getPayData(this.savedOrder.orderId);
            // .subscribe(result => {
            //   // this.payToMpay(result);
            // });
        }
    }



    payToMpay(object: PaymentUrlResponse) {
        // let returnUrl = `${location.origin}${this.accountLinkGeneratorService.getOrderConfirmationUrl(object.orderKey)}`;
        // var param = { ServiceID: object.serviceId, OrderKey: object.orderKey, ReturnUrl: returnUrl };

        const langCode = this.providerForm.get('lang')?.value;
        const mpassVal = this.providerForm.get('mpass')?.value;
        let returUrl = `${this.appConfigService.appConfig.APP_BASE_URL}`
        // this.appConfigService.appConfig.MDELIVERY_ORDER_LINK + `?orderId=${object.orderKey}&lang=${langCode}&mpass=${mpassVal}&serviceId=1`;
        //
        var param = { ServiceID: object.serviceId, OrderKey: object.orderKey, ReturnUrl: returUrl };
        this.post(object.payUrl!, param, "POST");
    }

    post(path: string, params: any, method: string) {
        console.log(params)
        method = method || "post";
        var form = document.createElement("form");
        form.hidden = true;
        form.setAttribute("method", method);
        form.setAttribute("action", path);
        for (var key in params) {
            if (params.hasOwnProperty(key)) {
                var hiddenField = document.createElement("input");
                hiddenField.setAttribute("type", "text");
                hiddenField.setAttribute("name", key);
                hiddenField.setAttribute("value", params[key]);
                form.appendChild(hiddenField);
            }
        }
        document.body.appendChild(form);
        form.submit();
    }


    getDataOrder(): void {
        this.ordersService.getOrderById(this.orderId)
            .subscribe(res => {
                this.orderData = res;
                this.savedOrder = res;
                this.returning = true
                console.log(this.orderData)
            })
    }


    doingPaymentAction(): void {
        const headers = new HttpHeaders({
            'Access-Control-Allow-Headers': 'Content-Type',
            'Access-Control-Allow-Origin': '*'
        })
        const orderId = this.activatedRoute.snapshot.queryParams.orderId
        const deliveryId = this.activatedRoute.snapshot.queryParams.deliveryId
        const returUrl = `${this.appConfigService.appConfig.APP_BASE_URL}`;
        this.paymentService.doingPayment(orderId, deliveryId, returUrl).
            subscribe(res => {
                this.payToMpay(res)
                //     const body = {}
                //     // @ts-ignore
                // this.http.post(`${res.payUrl}`, body, {headers} ).
                //     subscribe(res => console.log(res))
                console.log(res)
            })
    }

    getDeliveryDetails(): void {

        if (this.deliveryId) {
            this.loading = true;
            this.deliveryService.getDeliveryDetailsByDeliveryId(this.serviceId, this.orderId)
                .pipe(
                    finalize(
                        () => { this.loading = false }
                    )
                )
                .subscribe(res => {
                    this.deliveryDetails = res;
                    console.log(res)
                },
                    (err: HttpErrorResponse) => {
                        this.feedbackService.handleError(err);
                    }
                );
        }
    };

    getTotalProductsPrice(deliveryDetails: DeliveryDetailsModel) {
        let total = 0;

        deliveryDetails.products?.forEach((item) => {
            total += Number(item.price);
        });
        this.totalProductsPrice = total;
        return total;
    }

    getTotal(deliveryDetails: DeliveryDetailsModel) {

        if (deliveryDetails.carrierCost === undefined) {
            return this.totalProductsPrice + 0;
        }
        return this.totalProductsPrice + deliveryDetails.carrierCost;
    }

    getDateFromString(deliveryDetails: DeliveryDetailsModel, formatData: string) {
        if (deliveryDetails.orderDate !== undefined) {
            return this.datePipe.transform(deliveryDetails.orderDate, formatData);
        }
        return "";
    }

    ngAfterViewInit(): void {
        if(this.orderId) {
        setTimeout(() => {
            window.scrollTo({
                top: window.document.body.scrollHeight,
                behavior: "smooth"
            })
        }, 90)
        }
    }

}
