
<sp-general-step title="Step 1">
    <div class="main-form">
        <form [formGroup]="providerForm">
            <mat-form-field>
                <mat-label>IDNP</mat-label>
                <input matInput formControlName="idnp" />
                <mat-error *ngIf="hasError('idnp', 'required')">
                    IDNP is required.
                </mat-error>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Processing time</mat-label>
                <mat-select formControlName="processing">
                    <mat-option [value]="processingTimeEnum.ORDINARY">10 days - 30 MDL</mat-option>
                    <mat-option [value]="processingTimeEnum.URGENT">1 day - 60 MDL</mat-option>
                </mat-select>
                <mat-error *ngIf="hasError('processing', 'required')">
                    Please choose processing time option.
                </mat-error>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Product Code</mat-label>
                <input matInput formControlName="productCode" />
                <mat-error *ngIf="hasError('productCode', 'required')">
                    Product Code is required.
                </mat-error>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Pick-up Point Code</mat-label>
                <input matInput formControlName="pickUpPointCode" />
                <mat-error *ngIf="hasError('pickUpPointCode', 'required')">
                    Pick-up Point Code is required.
                </mat-error>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Language</mat-label>
                <mat-select formControlName="lang">
                    <mat-option [value]="languagesEnum.EN">English</mat-option>
                    <mat-option [value]="languagesEnum.RO">Romanian</mat-option>
                    <mat-option [value]="languagesEnum.RU">Russian</mat-option>
                </mat-select>
                <mat-error *ngIf="hasError('lang', 'required')">
                    Please choose a language.
                </mat-error>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Passive authentication</mat-label>
                <mat-select formControlName="mpass">
                    <mat-option [value]="true">True</mat-option>
                    <mat-option [value]="false">False</mat-option>
                </mat-select>
                <mat-error *ngIf="hasError('lang', 'required')">
                    Please choose a language.
                </mat-error>
            </mat-form-field>
            <div class="submit-block">
                <button *ngIf="!httpRequestInProgress; else SpinnerTemplate" class="submit-button" mat-flat-button
                        color="primary" (click)="submitForm()">
                    Next
                </button>
                <ng-template #SpinnerTemplate>
                    <mat-spinner diameter="50"></mat-spinner>
                </ng-template>
            </div>
        </form>
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
            <ng-container matColumnDef="keyName">
                <th mat-header-cell *matHeaderCellDef> Name</th>
                <td mat-cell *matCellDef="let element"> {{element.keyName}} </td>
            </ng-container>
            <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef> Type</th>
                <td mat-cell *matCellDef="let element"> {{element.type}} </td>
            </ng-container>
            <ng-container matColumnDef="meaning">
                <th mat-header-cell *matHeaderCellDef> Meaning</th>
                <td mat-cell *matCellDef="let element"> {{element.meaning}} </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>
</sp-general-step>

<sp-general-step title="Step 2">
    <div class="info-block">
        <ul>
            <li>
                <div class="title">OrderId :</div>
                <div class="value">{{savedOrder?.orderId ? savedOrder?.orderId : '-'}}</div>
            </li>
            <li>
                <div class="title">IDNP :</div>
                <div class="value">{{savedOrder?.receiver?.value ? savedOrder?.receiver?.value : '-'}}</div>
            </li>
            <li>
                <div class="title">Date :</div>
                <div class="value">{{savedOrder?.estimatedDate ? (savedOrder?.estimatedDate | date: 'dd/MM/yyyy') : '-'}}</div>
            </li>
            <li>
                <div class="title">Price :</div>
                <div class="value">{{savedOrder?.price ? savedOrder?.price + ' MDL' : '-'}}</div>
            </li>
        </ul>
        <div class="actions-block">
            <button class="action-button" mat-flat-button color="primary" [disabled]="!savedOrder" (click)="openDialog()">
                Confirm
            </button>
            <button class="action-button" mat-flat-button color="primary" [disabled]="!savedOrder" (click)="redirect()">
                Add
                Delivery
            </button>
        </div>
        <div *ngIf="savedOrder">
            * When you click on "Add delivery" button we will be redirected to
            https://mdelivery.dev.egov.md/public/shipping?orderId={{savedOrder?.orderId}}
            &lang={{this.providerForm.get('lang')?.value}}&mpass={{this.providerForm.get('mpass')?.value}}&serviceId=1
            where id is order id and providerId is id of Service provider.
        </div>
    </div>
</sp-general-step>

<sp-general-step title="Step 3">

    <div class="info-block">
        <h3>Summary</h3>
        <div>
            <p><strong>OrderId:  </strong> {{orderData?.id}} </p>
            <p><strong>DeliveryId:  </strong> {{orderData?.trackingId}} </p>
            <p><strong>Carrier name: </strong>{{orderData?.carrier}}</p>
            <p><strong>Estimation delivery time: </strong>{{orderData?.estimatedDate | date}}</p>
        </div>
        <div>
            <p><strong>Subtotal:  </strong> <span *ngIf="getTotalProductsPrice(deliveryDetails)"> {{ getTotalProductsPrice(deliveryDetails) }} MDL</span></p>
            <p><strong>Shipping price:  </strong> <span>{{orderData?.price}} MDL</span> </p>
            <strong>Total:  </strong>  <span *ngIf="getTotal(deliveryDetails)"> {{getTotal(deliveryDetails)}} MDL</span>
        </div>
        <div class="actions-block"><button class="action-button" mat-flat-button color="primary" (click)="doingPaymentAction()">pay</button></div>
    </div>

</sp-general-step>
