
<sp-header></sp-header>
<mat-sidenav-container class="main-page">
  <mat-sidenav mode="side" opened>
    <sp-sidebar-menu></sp-sidebar-menu>
  </mat-sidenav>
  <mat-sidenav-content>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
