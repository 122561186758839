
import {Component, Input} from '@angular/core';

@Component({
  selector: 'sp-general-step',
  template: `
    <div class="content-page">
      <sp-header [title]="title"></sp-header>
      <ng-content></ng-content>
    </div>
  `,
  styleUrls: ['./general-step.component.scss']
})
export class GeneralStepComponent {
  @Input() title = 'Step';

}
