
<sp-general-step title="Order by id">
  <div class="content">
    <form class="main-form" [formGroup]="searchForm">
      <mat-form-field>
        <mat-label>Search by id</mat-label>
        <input matInput formControlName="searchIdValue"/>
        <button matSuffix *ngIf="!httpRequestInProgress; else SpinnerTemplate" mat-flat-button color="primary" (click)="search()">
          <mat-icon matPrefix>search</mat-icon>
        </button>
        <ng-template #SpinnerTemplate>
          <mat-spinner matSuffix diameter="50"></mat-spinner>
        </ng-template>
        <mat-error *ngIf="hasError('searchIdValue', 'required')">
          This field is required.
        </mat-error>
      </mat-form-field>
    </form>
    <div class="section json-section" *ngIf="order || searchError">
      <ng-container *ngIf="order; else ErrorBlock">
        <ngx-json-viewer [json]="order"></ngx-json-viewer>
      </ng-container>
      <ng-template #ErrorBlock>
          <mat-error *ngIf="searchError">
            <p>ERROR!</p>
            <ngx-json-viewer [json]="errorJson"></ngx-json-viewer>
          </mat-error>
      </ng-template>
    </div>
    <div class="table-section">
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
        <ng-container matColumnDef="keyName">
          <th mat-header-cell *matHeaderCellDef> Name</th>
          <td mat-cell *matCellDef="let element"> {{element.keyName}} </td>
        </ng-container>
        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef> Type</th>
          <td mat-cell *matCellDef="let element"> {{element.type}} </td>
        </ng-container>
        <ng-container matColumnDef="meaning">
          <th mat-header-cell *matHeaderCellDef> Meaning</th>
          <td mat-cell *matCellDef="let element"> {{element.meaning}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  </div>
</sp-general-step>
