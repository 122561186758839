
import { Component } from '@angular/core';
import { AppConfigService } from 'src/app/core/services/app-config.service';

@Component({
  selector: 'sp-sidebar-menu',
  template: `
    <mat-action-list role="menu">
      <a mat-list-item></a>
      <a mat-list-item routerLink="/">
        <mat-icon aria-hidden="false" aria-label="home">home</mat-icon>
        <span>Create order</span>
      </a>
      <a mat-list-item routerLink="/get-order">
        <mat-icon aria-hidden="false" aria-label="pagview">pageview</mat-icon>
        <span>Get order</span>
      </a>
      <a mat-list-item [href]="appSwaggerUrl">
        <mat-icon aria-hidden="false" aria-label="api">code</mat-icon>
        <span>API</span>
      </a>
    </mat-action-list>
  `,
  styleUrls: ['./sidebar-menu.component.scss']
})
export class SidebarMenuComponent {
  constructor(public appConfigService: AppConfigService) { }
  appSwaggerUrl = this.appConfigService.appConfig.APP_BASE_URL + '/backend/swagger';
}
