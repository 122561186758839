import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Order } from '../models/Order.model';
import { AppConfigService } from './app-config.service';

@Injectable({ providedIn: 'root' })
export class OrdersService {
  constructor(
    private http: HttpClient,
    public appConfigService: AppConfigService
  ) { }
  backendUrl = this.appConfigService.appConfig.SERVER_URL;


  createOrder(data: any): Observable<Order> {
    return this.http.post(`${this.backendUrl}/order`, data);
  }

  getOrderById(id: string): Observable<Order> {
    return this.http.get(`${this.backendUrl}/order/${id}`);
  }


}
