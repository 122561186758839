
import {NgModule} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {CreateOrderFormComponent} from './create-order/create-order-form.component';
import {MatInputModule} from '@angular/material/input';
import {CommonModule} from '@angular/common';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatSelectModule} from '@angular/material/select';
import {MatCardModule} from '@angular/material/card';
import {MatMenuModule} from '@angular/material/menu';
import {MatFormFieldModule} from '@angular/material/form-field';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SharedModule} from '../shared/shared.module';
import {MatButtonModule} from '@angular/material/button';
import {GetOrderComponent} from './get-order/get-order.component';
import {MatTableModule} from '@angular/material/table';
import {NgxJsonViewerModule} from 'ngx-json-viewer';
import {MatProgressSpinnerModule, MatSpinner} from '@angular/material/progress-spinner';
import {UrlTestComponent} from './url-test/url-test.component';
import {ConfirmModalComponent} from './create-order/confirm-modal/confirm-modal.component';
import {MatDialog, MatDialogModule} from '@angular/material/dialog';

@NgModule({
  declarations: [
    CreateOrderFormComponent,
    GetOrderComponent,
    UrlTestComponent,
    ConfirmModalComponent
  ],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    MatSelectModule,
    MatCardModule,
    MatMenuModule,
    MatIconModule,
    SharedModule,
    MatButtonModule,
    FormsModule,
    MatTableModule,
    MatProgressSpinnerModule,
    NgxJsonViewerModule,
    MatDialogModule
  ],
  exports: [
    CreateOrderFormComponent,
    GetOrderComponent,
    UrlTestComponent
  ]
})
export class ComponentsModule {
}
