import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AppConfigModel } from '../models/app-config.model';

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {

  public appConfig!: AppConfigModel;
  constructor(public http: HttpClient) { }
  async loadAppConfig() {
    const jsonPath = "/assets/config/appsettings.json";
    return this.http.get<AppConfigModel>(jsonPath)
      .toPromise()
      .then((data: AppConfigModel) => { this.appConfig = data; })
      .catch(_ => {
        console.warn('Error loading appsettings.json, using environment file instead.');
        this.appConfig = environment as any;
        return Promise.resolve(this.appConfig);
      });
  }
}
