
import {Component, Input} from '@angular/core';

@Component({
  selector: 'sp-header',
  template: `
    <mat-toolbar color="primary">
      <h2 class="title">{{title}}</h2>
    </mat-toolbar>
  `,
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  @Input() title = 'Service provider demo';

}
