
import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'sp-url-test',
  templateUrl: './url-test.component.html',
})
export class UrlTestComponent implements OnInit {
  public href = '';

  constructor(private router: Router) {
  }

  ngOnInit(): void {
    this.href = this.router.url;
  }

}
