
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {CreateOrderFormComponent} from './components/create-order/create-order-form.component';
import {GetOrderComponent} from './components/get-order/get-order.component';
import {UrlTestComponent} from './components/url-test/url-test.component';

const routes: Routes = [
  {path: '', component: CreateOrderFormComponent},
  {path: 'get-order', component: GetOrderComponent},
  {path: 'url-test', component: UrlTestComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
